.carousel-ctn {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide {
    width: 100%;
    height: auto;
}
.slide-hidden {
    display: none;
}
.carousel-slide {
    border: none;
}
.carousel-btn {
    position: absolute;
    color: white;
    background-color: transparent;
    border: none;
}
.carousel-btn:hover {
    cursor: pointer;
}
.carousel-svg-btn {
    fill: rgba(255, 255, 255, 0.61);
}
.btn-prev {
    left: 1rem;
}
.btn-next {
    right: 1rem;
}

@media only screen and (min-width: 640px) {
    .carousel-svg-btn {
        scale: 1.2;
        transition: 0.2s;
    }
    .carousel-svg-btn:hover {
        fill: rgb(255, 255, 255);
        scale: 1.5;
    }
    .btn-prev {
        left: 2rem;
    }
    .btn-next {
        right: 2rem;
    }
}