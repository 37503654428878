.slider-ctn{
    display: grid;
    place-content: center;
    position: relative;
    overflow: hidden;
    --position: 50%;
}
.img-slider{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}
.img-before{
    position: absolute;
    inset: 0;
    width: var(--position);
}
.slider{
    position: absolute;
    inset: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
}
/* .slider:focus-visible ~ .slider-button {
    outline: 5px solid black;
    outline-offset: 3px;
} */
.slider-line {
    position: absolute;
    inset: 0;
    width: .2rem;
    height: 100%;
    background-color: white;
    z-index: 10;
    left: var(--position);
    transform: translateX(-50%);
    pointer-events: none;
}
.slider-button {
    position: absolute;
    background-color: white;
    color: black;
    padding: .5rem;
    border-radius: 100vw;
    display: grid;
    place-items: center;
    top: 50%;
    left: var(--position);
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 11;
    box-shadow: 2px 2px 2px rgba(39, 39, 39, 0.664);
}