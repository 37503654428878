.notfound-ctn{
    width: 100%;
    position:relative;
    margin-left: auto;
    margin-right: auto;
    padding: 8rem 1rem;
}
.notfound-wrapper{
    height: 100%;
    text-align: center;
}
.h2-notfound{
    color: var(--whiteColor);
    font-family: firaSans;
    font-size: 2rem;
}

@media only screen and (min-width: 640px) {
    .notfound-ctn{
        max-width: var(--maxWidth);
        margin: auto;
    }
}