.projets-ctn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 6rem 1rem 2rem 1rem;
}
.contentP {
    width: 100%;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 640px){
    .projets-ctn {
        max-width: var(--maxWidth);
        padding: 4rem 1rem;
    }
}
