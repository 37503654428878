.article-ctn{
    width: 100%;
    margin-bottom: 2rem;
}

.section-header{
    padding: 1rem 0rem;
}
.h4-projets{
    color: var(--secondColor);
    font-size: 1.25rem;
}
.project-title-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 0.5rem;
}
.project-title {
    color: var(--textColor);
    text-transform: uppercase;
}
.project-spliter {
    color: var(--redColor);
}

.section-pic {
    width: 100%;
    box-shadow: 0px 5px 10px rgb(12, 12, 12);
}
.img {
    display: block;
    max-width: 100%;
}
.img-fix {
    width: 100%;
    height: auto;
}

.section-para{
    background-color: var(--mainColorAlt);
    box-shadow: 0px 5px 10px rgb(12, 12, 12);
}
.section-text{
    width: 100%;
    padding: 1rem;
}
.h3-projets{
    font-family: firaSansLight;
    text-transform: uppercase;
    font-size: 1.25rem;
    /* color: var(--blueColor); */
}
.content-para {
    padding-top: 0.5rem;
}
.color-line-blue{
    height: 2px;
    margin: 0.5rem 0.1rem;
    background-color: var(--blueColor);
}
.cl-s {
    width: 4.9rem;
}
.cl-d {
    width: 6.6rem;
}


@media only screen and (min-width: 640px) {
    .article-ctn{
        margin-bottom: 4rem;
    }
    .section-header{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .section-text {
        /* width: 66%; */
        max-width: 700px;
    }
    .project-title-wrapper{
        padding: 0;
    }
    .section-para{
        padding: 1rem;
    }
    
}
