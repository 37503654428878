.home-ctn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 5rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.img-home-wrapper{
    display: none;
}
.img1-home{
    order: 1;
    box-shadow: 0 5px 10px rgb(12, 12, 12);
    z-index: 10;
}
.img2-home{
    order: 3;
    box-shadow: 0 5px 10px rgb(12, 12, 12);
    /* padding: 1rem 0 2rem 0; */
    margin: 1rem 0;
}
.home-text-ctn {
    width: 100%;
    order: 2;
}
.home-para{
    background-color: var(--mainColorAlt);
    padding: 2rem;
}
.h1-home-wrapper{
    padding-bottom: 1.5rem;
}
.h1-home{
    text-transform: uppercase;
    font-size: 2rem;
}
.color-line {
    width: 9.7rem;
    height: 3px;
    margin: 1rem 0;
    background-color: var(--secondColor);
}
.h2-home {
    font-family: firaSans;
    font-weight: normal;
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
}
.text-home {
    width: 100%;
    color: rgba(255, 255, 255, 0.726);
}
.inter-line{
    order: 4;
}
.mini-logo-ctn {
    order: 5;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 4rem 0;
}
.mini-logo {
    width: 15%;
}

@media only screen and (min-width: 640px){
    .home-ctn {
        max-width: var(--maxWidth);
        padding: 2rem 1rem;
        flex-wrap: wrap;
        flex-direction: row;
        column-gap: 0rem;
    }
    .img-home-wrapper{
        display:block;
        width: 100%;
        /* max-width: 1400px; */
        height: 30vw;
        position: relative;

        margin: auto;
        padding-top: 2rem;
    }
    .img1-home{
        position: absolute;
        width: 50%;
        min-width: calc(50% - 0.5rem);
        left: 1rem;
        box-shadow: 0px 5px 10px rgb(12, 12, 12);
        z-index: 1;
    }
    .img2-home{
        position:absolute;
        width: 50%;
        padding: 0;
        min-width: calc(50% - 0.5rem);
        
        top: 3rem;
        right: 1rem;
        box-shadow: 0px 5px 10px rgb(12, 12, 12);
        z-index: 2;
    }
    .mobile-img{
        display: none;
    }
    .home-text-ctn {
        order: 3;
        padding-bottom: 1rem;
    }
    .home-para{
        padding: 4rem;
        padding-top: 7rem;
        margin-top: -4rem;
    }
    .mini-logo-ctn{
        max-width: 600px;
        margin: auto;
    }
    .mini-logo {
        width: 10%;
    }

}
