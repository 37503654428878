.contact-ctn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 6rem 1rem;
}
.contact-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: 0px 5px 10px rgb(12, 12, 12);
}
.contact-info-ctn {
    width: 100%;
    background-color: var(--mainColorAlt);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}
.contact-port {
    width: 40%;
    height: auto;
}
.contact-info {
    width: 55%;
    padding-top: 0.5rem;
}
.h2-contact {
    max-width: 100%;
    color: var(--secondColor);
    font-size: 1.5rem;
    word-wrap:break-word;
}
.para-contact{
    padding-top: 1rem;
}

.contact-form-ctn {
    width: 100%;
    padding: 1rem;
    background-color: var(--mainColorAlt);
    /* box-shadow: 0px 5px 10px rgb(12, 12, 12); */
}
.contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.contact-form-labels {
    font-family: 'Roboto', sans-serif;
    font-family: firaSans;
    font-size: 1.25rem;
    color: var(--whiteColor);

    display: flex;
    flex-direction: row;
    align-items: center;
}
.label-colors{
    position: relative;
    right: 0.5rem;
}
.label-name{
    font-family: firaSansLight;
    font-size: 1.25rem;
}

.input-form-contact{
    padding: 0.5rem;
    margin-bottom: 1rem;
    color: var(--whiteColor);
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    background-color: var(--mainColor);
    border: none;
    border-radius: 3px;
}
.contact-message {
    min-height: 150px;
    resize: none;
}
.contact-send-btn{
    max-width: fit-content;
    color: var(--textColor);
    font-size: 1.25rem;
    padding: 3px;
    margin: auto;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    background-size: 250%;
    background-image: linear-gradient( to left, var(--whiteColor) 40%, var(--secondColor) 60%);  
}

.text-btn {
    padding: 0.5rem 1rem;
    font-family: fjallaOne;
    color: white; 
    text-transform: uppercase;
    color: var(--mainColor); 
    background-color: var(--secondColor);
    transition: 0.3s;
}


@media only screen and (min-width: 640px) {

    .contact-ctn {
        max-width: var(--maxWidth);
        padding: 2rem 1rem;
        margin: auto;
    }
    .contact-wrapper{
        /* padding: 2rem; */
        flex-direction: row;
        align-items: stretch;
        justify-content:flex-start;
        background-color: var(--mainColorAlt);
        box-shadow: -3px 5px 10px rgb(12, 12, 12);
    }

    .contact-info-ctn {
        width: 50%;
        max-width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding: 2rem;
        background-color: var(--blackColor);
        box-shadow: none;
    }

    .contact-form-ctn {
        width: 100%;
        height: 100%;
        padding: 2rem 2rem ;
        box-shadow: none;
    }
    .contact-send-btn{
        transition: 0.4s;  
    }
    .contact-send-btn:hover {
        background-position: right;
        color: var(--mainColor);
    }
    .contact-port {
        width: 100%;
        padding: 0rem;
    }
    .contact-info {
        width: 100%;
        padding-top: 2rem;
    }

}
