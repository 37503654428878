:root {
  --mainColor: rgb(27, 27, 27);
  --mainColorAlt: rgb(37, 37, 37);
  /* --secondColor: rgb(199, 165, 15); */
  --secondColor: rgb(229, 195, 52);
  --textColor: rgb(255, 255, 255);

  --blackColor: rgb(12, 12, 12);
  --whiteColor: rgb(241, 241, 241);
  --redColor: rgb(223, 77, 41);
  --blueColor: rgb(108, 212, 255);

  --maxWidth: 1200px;
}

@font-face {
  font-family: firaSansLight;
  src: url(../assets/fonts/Fira_Sans_Condensed/FiraSansCondensed-Light.ttf);
}
@font-face {
  font-family: firaSans;
  src: url(../assets/fonts/Fira_Sans_Condensed/FiraSansCondensed-Regular.ttf);
}
@font-face {
  font-family: firaSansIta;
  src: url(../assets/fonts/Fira_Sans_Condensed/FiraSansCondensed-LightItalic.ttf);
}
@font-face {
  font-family: fjallaOne;
  src: url(../assets/fonts/Fjalla_One/FjallaOne-Regular.ttf);
}

/* //////////////////////////////////////////////////////////// */

* {
  font-family: 'Roboto', sans-serif;
}
.main-ctn {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--mainColor);
  display: flex;
  flex-direction: column;
}


h1, h2, h3, h4, h5, h6 {
  color: white;
  font-family: firaSansLight;
}
h1, h2 {
  font-family: fjallaOne;
}

p {
  color: var(--textColor);
}
img {
    width: 100%;
    height: auto;
    display: block
}
li {
  list-style-type: none;
}
.inter-line {
  width: 100%;
  height: 1px;
  background-color: white;
}

@media only screen and (min-width: 640px) {
  .main-page-ctn{
    width: 100vw;
    overflow: hidden;
    scrollbar-gutter: stable;
  }
}