.header-ctn {
    width: 100%;
    background-color: var(--blackColor);
    position: fixed;
    z-index: 20;
}
.header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo-ctn {
    position: relative;
    overflow: hidden;
    padding: 1rem;
}
.title {
    position: relative;
    color: var(--textColor);
    font-family: firaSansLight;
    font-size: 1.7rem;
    z-index: 1;
}
.title-clone {
    position: absolute;
    text-align: end;
    inset: 0;
    padding: 1rem;
    pointer-events: none;
    font-family: firaSansLight;
    font-size: 1.7rem;
}
.clone1 {
    color: var(--blueColor);
    transform: translateX(6px);
}
.clone2 {
    color: var(--secondColor);
    transform: translateX(12px);
}
.clone3 {
    color: var(--redColor);
    transform: translateX(18px);
}

.navbar-ctn {
    width: 100%;
    height: 100vh;
    max-height: 100%;
    color: white;
    background-color: var(--mainColor);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self:center;
    padding-left: 20%;
    gap: 3rem;

    z-index: 20;
    transition: 0.5s;
    transform: translateX(+100%);
}
.responsive-nav {
    transform: none;
}
.nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 0rem;
}
.link {
    color: white;
    font-family: firaSans;
    font-size: 3rem;
    text-decoration: none;
    text-transform: uppercase;
}

.nav-btn{
    padding-right: 1rem;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    opacity: 1;
}

@media only screen and (min-width: 640px) {
    .header-ctn {
        width: 100vw;
        overflow: hidden;
        scrollbar-gutter: stable;
        position: static;
    }
    .header-wrapper {
        max-width: var(--maxWidth);
        margin: 0 auto;
    }

    .clone1 {
        transform: none;
        transition: transform 0.25s;
    }
    .clone2 {
        transform: none;
        transition: transform 0.50s;
    }
    .clone3 {
        transform: none;
        transition: transform 0.60s;
    }

    .header-ctn:hover .clone1 {
        transform: translateX(6px); 
    }
    .header-ctn:hover .clone2 {
        transform: translateX(12px); 
    }
    .header-ctn:hover .clone3 {
        transform: translateX(18px); 
    }

    .title {
        z-index: 10;
    }

    .navbar-ctn{
        height: 100%;
        position: relative;
        background-color: var(--blackColor);
        flex-direction: row;
        align-items: center;
        gap: 0;
        transform: none;
        padding-left: 0;
        padding-right: 1rem;
    }
    .red-border{
        width: 2px;
        height: 15px;
        background-color: var(--redColor);
        transition: opacity 0.35s;
        opacity: 1;
    }
    .navbar-ctn:hover .red-border {
        opacity: 0;
    }
    .link {
        font-size: 1.2rem;
        padding: 1rem;
        background-size: 100% 200%;
        background-image: linear-gradient( 180deg, var(--mainColor) 50%, var(--blackColor) 30%);
        background-position: bottom;
        transition: 0.25s;
    }
    .link:hover {
        color: var(--secondColor);
        background-position: top;
    }
    .nav-btn {
        display: none;
        opacity: 0;
    }

}

/* @media only screen and (orientation: landscape) and (max-height: 640px) {
    .header-ctn {
        position: static;
        background-color: red;
    }
} */